import React from 'react'
import { Link } from 'gatsby'

import Image from './Image'
import Content from './Content'
import './ServicesList.css'

const ServicesList = ({ list, subtitle }) => (
  <div className="services wide">
    {list.map((item, index) => (
      <div className="row half flex" key={`item${index}`}>
        <div className="item">
          <h2>{item.title}</h2>
          <Content src={item.description} />
          {!!item.link && (
            <Link to={item.link} className="link">
              View projects
            </Link>
          )}
        </div>
        {!!item.mainImage && (
          <div className="item">
            <div className="image">
              <Image src={item.mainImage} alt={item.title} />
            </div>
          </div>
        )}
      </div>
    ))}
  </div>
)

export default ServicesList
