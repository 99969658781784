import React from 'react'
import { graphql } from 'gatsby'

import PageHeader from '../components/PageHeader'
import ServicesList from '../components/ServicesList.js'
import Layout from '../components/Layout.js'

// Export Template for use in CMS preview
export const ServicesPageTemplate = ({
  title,
  sectionHero,
  sectionServices
}) => (
  <div className="about-page">
    <PageHeader title={title} backgroundImage={sectionHero.featuredImage} />

    {/* services section */}

    <section>
      <ServicesList list={sectionServices} />
    </section>
  </div>
)

const ServicesPage = ({ data: { page, team } }) => (
  <Layout
    meta={page.frontmatter.meta || false}
    title={page.frontmatter.title || false}
  >
    <ServicesPageTemplate {...page} {...page.frontmatter} body={page.html} />
  </Layout>
)

export default ServicesPage

export const pageQuery = graphql`
  query ServicesPage($id: String!) {
    page: markdownRemark(id: { eq: $id }) {
      ...Meta
      html
      frontmatter {
        title
        sectionHero {
          featuredImage
        }
        sectionServices {
          title
          mainImage
          description
          link
        }
      }
    }
  }
`
